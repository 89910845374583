<template>
  <div>
    <EmulatorSectionHeader title="Initialization" />
    <div>
      <div class="w-100 text-center mb-3">
        <button class="btn btn-white w-100 scope-option" @click="removeScope">
          <img :src="`/images/emulator/${scope.name}.svg`" />
          <span>{{ scope.label }}</span>
          <ShuffleIcon
            v-if="scopes.length > 1"
            size="15"
            class="ms-sm-2 text-muted scope-button-icon"
          />
        </button>
      </div>
      <div class="section" v-if="scope.products.length > 1">
        <label class="mb-1">Use Case</label>
        <p class="small text-muted mb-3">What tasks do you want to execute?</p>
        <div class="btn-group-toggle row gx-2 mb-4">
          <div v-for="product in scope.products" :key="product.name" class="col-12 my-1">
            <EmulatorProductSelector
              v-if="product.generallyAvailable || featureUniqueForAtomic"
              :product="product"
              :products="products"
              @toggle="toggleProduct"
            />
          </div>
        </div>
      </div>

      <div class="section" v-if="productsIncludeDeposit">
        <label class="mb-1">Deposit Accounts</label>
        <p class="small text-muted mb-3">
          Prompt the user to choose from their two available deposit accounts.
        </p>
        <CustomSwitch
          :value="useMultipleAccounts"
          label="Multiple Accounts"
          @input="toggleUseMultipleAccounts"
        />
      </div>

      <div class="section" v-if="productsIncludeTax">
        <label class="mb-1">Tax Forms</label>
        <p class="small text-muted mb-3">Select which forms to pull.</p>
        <div class="btn-group-toggle row gx-2 mb-4">
          <div class="col">
            <label
              class="btn w-100 btn-white"
              :class="{ active: taxForms.includes('w2s') }"
              @click="() => setTaxForms('w2s')"
            >
              W-2s
            </label>
          </div>
          <div class="col">
            <label
              class="btn w-100 btn-white"
              :class="{ active: taxForms.includes('1040s') }"
              @click="() => setTaxForms('1040s')"
            >
              1040s
            </label>
          </div>
        </div>
      </div>

      <div class="section">
        <label class="mb-1">Starting Screen</label>
        <p class="small text-muted mb-3">The first screen the user will see.</p>
        <div class="btn-group-toggle row gx-2 mb-4">
          <div class="col">
            <label
              class="btn w-100 btn-white"
              @click="() => setDeeplinkStep(undefined)"
              :class="{ active: !deeplink.step }"
            >
              {{ startingScreenWelcomeTitle }}
            </label>
          </div>
          <div class="col">
            <label
              class="btn w-100 btn-white"
              @click="() => setDeeplinkStep('search-company')"
              :class="{ active: deeplink.step === 'search-company' }"
            >
              Search
            </label>
          </div>
          <div v-if="!isScopePayLink" class="col">
            <label
              class="btn w-100 btn-white"
              @click="() => setDeeplinkStep('login-company')"
              :class="{ active: deeplink.step === 'login-company' }"
            >
              Login
            </label>
          </div>
        </div>
        <div class="form-group" v-if="deeplink.step === 'login-company'">
          <h6 data-v-75bf5b94="" class="header-pretitle mb-2">Company ID</h6>
          <input
            class="form-control"
            placeholder="5ec2fbf4398fed000810a42c"
            type="text"
            v-model="deeplink.companyId"
          />
        </div>
      </div>

      <div class="section" v-if="showSearchRulesSection">
        <label class="mb-1">Search Experiences</label>
        <div>
          <p class="small text-muted mb-3">Select a search experience to preview.</p>
          <v-select
            v-model="selectedSearchRule"
            :options="filteredSearchRules"
            :reduce="(rule) => rule._id"
            :get-option-label="
              (rule) => rule.name || `Rule ${filteredSearchRules.indexOf(rule) + 1}`
            "
            placeholder="Select a search rule"
            @input="onSearchRuleSelected"
          />
        </div>
      </div>

      <div class="section">
        <label class="mb-1">Language</label>
        <p class="small text-muted mb-3">Language used for copy throughout Transact.</p>
        <div class="btn-group-toggle row gx-2 mb-4">
          <div class="col" v-for="language in languages" :key="language.code">
            <label
              class="btn w-100 btn-white"
              @click="() => selectLanguage(language.code)"
              :class="{ active: settings.language === language.code }"
            >
              {{ language.flag }} {{ language.title }}
            </label>
          </div>
        </div>
      </div>

      <div
        v-if="featureUniqueForAtomic && !hasUserLinkContinuousAccessEnabled && !isScopePayLink"
        class="section"
      >
        <label class="mb-1">Demo</label>
        <p class="small text-muted mb-3">Emulate TrueAuth experience for supported companies.</p>
        <CustomSwitch :value="useUplinkDemo" label="TrueAuth" @input="toggleUseUplinkDemo" />
      </div>
    </div>
  </div>
</template>

<script>
import { ShuffleIcon } from 'vue-feather-icons'
import { featureUniqueForAtomic } from '@/lib/customer-customizations'
import { mapState, mapMutations, mapActions, mapGetters } from 'vuex'
import { PRODUCTS, SCOPES } from '@atomicfi/constants-shared'
import CustomSwitch from '@/components/MainContent/CustomSwitch'
import EmulatorProductSelector from '@/components/Modules/Emulator/EmulatorProductSelector'
import EmulatorSectionHeader from '@/components/Modules/Emulator/EmulatorSectionHeader'

export default {
  name: 'EmulatorInitialization',
  components: {
    CustomSwitch,
    EmulatorProductSelector,
    EmulatorSectionHeader,
    ShuffleIcon,
  },
  props: {
    searchRule: {
      type: String,
      default: '',
    },
  },
  data: () => ({
    availableProducts: [],
    distribution: {
      type: 'total',
      amount: undefined,
      action: 'create',
    },
    selectedSearchRule: null,
  }),
  watch: {
    products: {
      handler() {
        if (this.selectedSearchRule) {
          const rule = this.filteredSearchRules.find((rule) => rule._id === this.selectedSearchRule)
          if (!rule) {
            this.selectedSearchRule = null
            this.$emit('update:searchRule', null)
          }
        }
      },
      immediate: true,
    },
  },
  computed: {
    ...mapState('emulator', [
      'deeplink',
      'taxForms',
      'languages',
      'products',
      'scope',
      'settings',
      'useMultipleAccounts',
      'useUplinkDemo',
    ]),
    ...mapState('customer', ['activeCustomer']),
    ...mapGetters('customer', ['scopes', 'hasUserLinkContinuousAccessEnabled']),

    isScopePayLink() {
      return this.scope?.name === SCOPES.PAY_LINK
    },
    startingScreenWelcomeTitle() {
      return 'Welcome'
    },
    productsIncludeDeposit() {
      return this.products.includes(PRODUCTS.DEPOSIT)
    },
    productsIncludeTax() {
      return this.products.includes('tax')
    },
    hasSearchRules() {
      return this.filteredSearchRules.length > 0
    },

    showSearchRulesSection() {
      return (
        this.products.length === 1 &&
        this.filteredSearchRules.some((rule) => rule.product === this.products[0])
      )
    },

    filteredSearchRules() {
      if (!this.products.length) return []
      return (
        this.activeCustomer?.features?.searchRules?.filter(
          (rule) => rule.product === this.products[0]
        ) || []
      )
    },
  },
  methods: {
    ...mapMutations('emulator', [
      'setProducts',
      'setTaxForms',
      'setUseMultipleAccounts',
      'setScope',
      'setUseUplinkDemo',
    ]),
    ...mapActions('emulator', ['updateLanguage', 'updateTaxFormType', 'updateSearchRule']),
    featureUniqueForAtomic,
    setDeeplinkStep(deeplinkStep) {
      this.deeplink.step = deeplinkStep

      this.$analytics.track({
        event: 'Set Emulator Deeplink Step',
        payload: {
          deeplinkStep,
        },
      })
    },
    setTaxForms(taxFormType) {
      const taxForm = [taxFormType]

      this.$analytics.track({
        event: 'Set Emulator Tax Form Type',
        payload: {
          taxFormType,
        },
      })

      this.updateTaxFormType(taxForm)
    },
    toggleProduct(productToToggle) {
      let products = this.products
      if (products.includes(productToToggle.name)) {
        products = products.filter((product) => product !== productToToggle.name)

        this.$analytics.track({
          event: 'Remove Emulator Product',
          payload: {
            productRemoved: productToToggle.name,
          },
        })
      } else {
        products.push(productToToggle.name)

        this.$analytics.track({
          event: 'Add Emulator Product',
          payload: {
            productAdded: productToToggle.name,
          },
        })
      }

      this.setProducts(products)
    },
    selectLanguage(language) {
      this.updateLanguage({ analytics: this.$analytics, language })
    },
    toggleUseMultipleAccounts(value) {
      this.setUseMultipleAccounts(value)
    },
    toggleUseUplinkDemo(value) {
      this.setUseUplinkDemo(value)
    },
    removeScope() {
      this.setScope(undefined)
      this.setProducts([])
      this.setDeeplinkStep(undefined)
    },
    onSearchRuleSelected(ruleId) {
      this.$emit('update:searchRule', ruleId)
      this.updateSearchRule(ruleId)
    },
  },
  created() {
    if (this.scope.name === SCOPES.PAY_LINK) {
      this.toggleUseUplinkDemo(true)
    }
  },
}
</script>

<style lang="scss" scoped>
.custom-control-label {
  font-size: 0.8rem;
  padding-top: 3px;
}
.scope-option {
  position: relative;
  display: inline-block;
  text-align: left;
  font-size: 18px;
  padding: 20px;
  margin-bottom: 15px;
  img {
    width: 30px;
    margin-right: 10px;
  }
  span {
    position: relative;
    top: 2px;
  }
  .scope-button-icon {
    position: absolute;
    right: 20px;
    bottom: 26px;
  }
}
.section {
  margin-bottom: 2rem;

  &:last-child {
    margin-bottom: 0;
  }
}

:deep(.v-select) {
  .vs__dropdown-toggle {
    padding: 4px 0;
    border-color: #dee2e6;
  }

  .vs__selected {
    margin: 0 2px;
  }

  .vs__search {
    margin: 0;
  }
}
</style>
