<template>
  <div>
    <TaskOffState v-if="showNewUserScreen" />

    <!-- New Product Tabs -->
    <ul class="nav nav-tabs mb-3">
      <li class="nav-item" v-for="product in productOptions" :key="product">
        <a
          class="nav-link"
          :class="{ active: isProductActive(product) }"
          href="#"
          :id="`${product}-tab`"
          @click.prevent="selectProduct(product)"
        >
          {{ product | startCase }}
        </a>
      </li>
    </ul>

    <div class="card">
      <div class="card-header">
        <div class="row align-items-center">
          <div class="col">
            <form class="row align-items-center" @submit.prevent>
              <div class="col-auto pe-0">
                <div v-if="!loadingTasks" style="width: 16px">
                  <SearchIcon size="16" class="text-muted" />
                </div>
                <div
                  v-else
                  class="spinner-border spinner-border-sm text-muted"
                  role="status"
                  style="position: relative; top: -1px"
                ></div>
              </div>
              <div class="col">
                <input
                  type="text"
                  class="form-control form-control-flush search"
                  placeholder="Search by user identifier or taskId"
                  :value="query"
                  @input="updateQuery"
                />
              </div>
              <div class="col-auto">Showing {{ tasks.length }} of {{ totalTasks }} tasks</div>
              <div class="col-auto">
                <TaskFilterStatus />
              </div>
              <div class="col-auto">
                <TaskFilterDateRange />
              </div>
              <div class="col-auto">
                <label for="tasks-per-page" class="visually-hidden">Tasks per page</label>
                <select
                  id="tasks-per-page"
                  class="form-select form-select-sm"
                  v-model.number="limit"
                  @change="onLimitChange"
                  :disabled="loadingTasks"
                >
                  <option value="25">25 per page</option>
                  <option value="50">50 per page</option>
                  <option value="100">100 per page</option>
                </select>
              </div>
              <div class="col-auto">
                <button
                  type="button"
                  @click="resetFilters"
                  class="btn btn-outline-secondary btn-sm me-2"
                  :disabled="loadingTasks"
                >
                  Reset
                </button>
                <button
                  type="button"
                  @click="showDownloadConfirmation"
                  class="btn btn-outline-primary btn-sm me-2"
                >
                  Export
                </button>
                <button
                  type="button"
                  @click="refresh"
                  class="btn btn-primary btn-sm"
                  :disabled="loadingTasks"
                >
                  Refresh
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div class="card-body p-0">
        <template>
          <section class="table-responsive mb-0" v-if="tasks && tasks.length > 0">
            <table class="table card-table mb-0">
              <thead>
                <tr>
                  <th @click="sortBy('createdAt')" class="sortable">
                    Date
                    <SortIcon
                      v-if="sortKey === 'createdAt'"
                      :ascending="sortAscending"
                      class="ms-1"
                    />
                  </th>
                  <th @click="sortBy('user.identifier')" class="sortable">
                    User ID
                    <SortIcon
                      v-if="sortKey === 'user.identifier'"
                      :ascending="sortAscending"
                      class="ms-1"
                    />
                  </th>
                  <th @click="sortBy('externalStatus')" class="sortable">
                    Status
                    <SortIcon
                      v-if="sortKey === 'externalStatus'"
                      :ascending="sortAscending"
                      class="ms-1"
                    />
                  </th>
                  <th @click="sortBy('company.name')" class="sortable">
                    Company
                    <SortIcon
                      v-if="sortKey === 'company.name'"
                      :ascending="sortAscending"
                      class="ms-1"
                    />
                  </th>
                  <th @click="sortBy('connector.name')" class="sortable">
                    Connector
                    <SortIcon
                      v-if="sortKey === 'connector.name'"
                      :ascending="sortAscending"
                      class="ms-1"
                    />
                  </th>

                  <th
                    v-if="isDepositProduct"
                    @click="sortBy('settings.deposit.distributionType')"
                    class="sortable"
                  >
                    Distribution Type
                    <SortIcon
                      v-if="sortKey === 'settings.deposit.distributionType'"
                      :ascending="sortAscending"
                      class="ms-1"
                    />
                  </th>
                  <th
                    v-if="isDepositProduct"
                    @click="sortBy('settings.deposit.distributionAmount')"
                    class="sortable"
                  >
                    Deposit Amount
                    <SortIcon
                      v-if="sortKey === 'settings.deposit.distributionAmount'"
                      :ascending="sortAscending"
                      class="ms-1"
                    />
                  </th>

                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                <TaskRow
                  v-for="task in sortedTasks"
                  :key="task._id"
                  :task="task"
                  :currentProduct="currentProduct"
                />
              </tbody>
            </table>
          </section>

          <TaskNoTasksFound v-else-if="!loadingTasks" />

          <div v-if="loadingTasks" class="text-center py-4">
            <div class="spinner-border text-primary" role="status">
              <span class="visually-hidden">Loading...</span>
            </div>
          </div>
        </template>
      </div>

      <div class="card-footer" v-if="showPagination">
        <DataPager
          :limit="limit"
          :totalResults="totalTasks"
          :initialSkip="skip"
          :showPages="true"
          @change="onPageChange"
        />
      </div>

      <transition name="modal">
        <div
          v-if="showDownloadModal"
          class="modal"
          tabindex="-1"
          role="dialog"
          aria-labelledby="downloadModalLabel"
          style="display: block"
        >
          <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content">
              <div class="modal-header">
                <h5 class="modal-title" id="downloadModalLabel">Download Confirmation</h5>
                <button
                  type="button"
                  class="btn-close"
                  @click="closeModal"
                  aria-label="Close"
                ></button>
              </div>
              <div class="modal-body">
                <p>Total Records: {{ tasks.length }}</p>
                <p>
                  You are about to export all tasks matching your current filters. This may include
                  tasks not currently loaded in the table. Do you want to proceed?
                </p>
              </div>
              <div class="modal-footer">
                <button
                  type="button"
                  class="btn btn-outline-secondary"
                  @click="closeModal"
                  :disabled="downloadingCSV"
                >
                  Cancel
                </button>
                <button
                  type="button"
                  class="btn btn-primary"
                  @click="confirmDownload"
                  :disabled="downloadingCSV"
                >
                  <span
                    v-if="downloadingCSV"
                    class="spinner-border spinner-border-sm me-2"
                    role="status"
                  ></span>
                  {{ downloadingCSV ? 'Downloading...' : 'Download CSV' }}
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import TaskBase from '@/components/Modules/Task/TaskBase'
import TaskRow from '@/components/Modules/Task/TaskRow'
import TaskFilterStatus from '@/components/Modules/Task/TaskFilter/TaskFilterStatus'
import TaskNoTasksFound from '@/components/Modules/Task/TaskNoTasksFound'
import TaskOffState from '@/components/Modules/Task/TaskOffState'
import TaskFilterDateRange from '@/components/Modules/Task/TaskFilter/TaskFilterDateRange'
import { debounce } from 'lodash-es'
import { mapState, mapActions } from 'vuex'
import { BANNO_STATUSES } from '@/utils/constants'
import { SearchIcon } from 'vue-feather-icons'
import moment from 'moment'
import TaskService from '@/services/TaskService'
import SortIcon from '@/components/Modules/Task/TaskFilter/SortIcon'
import { TASK_FILTER_PRODUCT_OPTIONS } from '@/lib/task-filter-product-options'
import { PRODUCTS } from '@atomicfi/constants-shared'
import DataPager from '@/components/MainContent/DataPager'

export default {
  name: 'Tasks',
  components: {
    SearchIcon,
    TaskRow,
    TaskFilterStatus,
    TaskFilterDateRange,
    TaskNoTasksFound,
    TaskOffState,
    SortIcon,
    DataPager,
  },
  extends: TaskBase,
  watch: {
    tasks: {
      immediate: true,
      handler(newTasks) {},
    },
    activeEnvironment: {
      immediate: true,
      async handler() {
        await this.initializeDefaultProduct()
      },
    },
  },
  data: () => ({
    showDownloadModal: false,
    downloadingCSV: false,
    isResetting: false,
    sortKey: 'createdAt',
    sortAscending: false,
    productOptions: Object.values(TASK_FILTER_PRODUCT_OPTIONS),
    defaultProduct: 'DEPOSIT',
  }),
  computed: {
    taskGroups: function () {
      let taskGroups = {}
      this.tasks.forEach(function (task) {
        if (task.taskWorkflow) {
          if (taskGroups[task.taskWorkflow]) {
            taskGroups[task.taskWorkflow].push(task)
          } else {
            taskGroups[task.taskWorkflow] = [task]
          }
        } else {
          taskGroups[task._id] = [task]
        }
      })
      return taskGroups
    },
    ...mapState('environment', ['activeEnvironment']),
    ...mapState('task', ['loadingTasks', 'filters', 'tasks', 'totalTasks', 'limit', 'skip']),
    hasTasks() {
      return Array.isArray(this.tasks) && this.tasks.length > 0
    },
    showLoadMoreButton() {
      return this.hasTasks && !this.loadingTasks && this.tasks.length < this.totalTasks
    },
    showNewUserScreen() {
      return (
        !this.hasTasks &&
        !this.loadingTasks &&
        !this.filters.query &&
        (!this.filters.product || this.filters.product.length === 0) &&
        (!this.filters.externalStatus || this.filters.externalStatus.length === 0) &&
        !this.filters.startDate &&
        !this.filters.endDate
      )
    },
    showNoTasksFound() {
      return !this.hasTasks && !this.loadingTasks && this.filters.query
    },
    showBannoPeopleLink() {
      return this.bannoStatus === BANNO_STATUSES.ACTIVE && this.bannoShortId
    },
    showTaskSearchResults() {
      return this.hasTasks || this.loadingTasks
    },
    query: {
      get() {
        return this.$store.state.task.filters.query
      },
      set(value) {
        this.updateQueryFilter(value)
      },
    },
    isLoading() {
      return this.loadingTasks || this.downloadingCSV || this.isResetting
    },
    sortedTasks() {
      if (!this.tasks) return []

      const sorted = [...this.tasks].sort((a, b) => {
        let aVal = this.getNestedValue(a, this.sortKey)
        let bVal = this.getNestedValue(b, this.sortKey)

        if (this.sortKey === 'createdAt') {
          aVal = new Date(aVal)
          bVal = new Date(bVal)
        }

        if (aVal === undefined || aVal === null) return 1
        if (bVal === undefined || bVal === null) return -1

        if (aVal < bVal) return this.sortAscending ? -1 : 1
        if (aVal > bVal) return this.sortAscending ? 1 : -1
        return 0
      })

      return sorted
    },
    currentProduct() {
      return (this.filters.product?.[0] || this.defaultProduct || '').toLowerCase()
    },
    isDepositProduct() {
      return this.currentProduct === PRODUCTS.DEPOSIT.toLowerCase()
    },
    showPagination() {
      return this.hasTasks && this.totalTasks > this.limit
    },
  },
  methods: {
    ...mapActions('task', [
      'getTaskList',
      'toggleProductFilter',
      'toggleStatusFilter',
      'updateDateRangeFilter',
      'updateQueryFilter',
      'loadMoreTasks',
    ]),
    async loadMore() {
      this.$analytics.track({
        event: 'Load More Tasks',
        payload: {
          skip: this.tasks.length,
        },
      })

      await this.loadMoreTasks()
    },
    updateQuery(e) {
      this.query = e.target.value
      this.debouncedIdentifierSearch()
    },
    debouncedIdentifierSearch: debounce(function () {
      const query = this.query

      this.$analytics.track({
        event: 'Search Tasks',
        payload: {
          query,
        },
      })

      this.updateQueryFilter(query)
    }, 500),
    async refresh() {
      if (!this.loadingTasks) {
        this.$analytics.track({
          event: 'Refresh Tasks',
        })
        await this.getTaskList()
      }
    },
    async resetFilters() {
      if (this.loadingTasks) return

      try {
        this.$store.commit('task/setLoadingTasks', true)
        this.query = ''
        await this.$store.dispatch('task/resetFilters')

        this.$nextTick(() => {
          const dateRangeFilter = this.$children.find(
            (child) => child.$options.name === 'TaskFilterDateRange'
          )
          if (dateRangeFilter) {
            const last30DaysPreset = dateRangeFilter.presets.find(
              (preset) => preset.label === 'Last 30 Days'
            )
            if (last30DaysPreset) {
              dateRangeFilter.applyPreset(last30DaysPreset)
            }
          }
        })

        this.$analytics.track({
          event: 'Reset Task Filters',
        })
      } catch (error) {
        console.error('Failed to reset filters:', error)
        this.$toasted.error('Failed to reset filters. Please try again.')
      } finally {
        this.$store.commit('task/setLoadingTasks', false)
      }
    },
    async confirmDownload() {
      let url, link
      try {
        this.downloadingCSV = true

        // Get current filters without pagination
        const exportFilters = {
          query: this.filters.query || undefined,
          externalStatus: this.filters.externalStatus?.filter(Boolean) || undefined,
          product: this.filters.product?.filter(Boolean) || undefined,
          startDate: this.filters.startDate || undefined,
          endDate: this.filters.endDate || undefined,
        }

        const response = await TaskService.exportCsv(exportFilters)

        if (response.headers.get('content-type')?.includes('application/json')) {
          let errorMessage = 'Export failed'
          try {
            const errorText = new TextDecoder().decode(response.body)
            const error = JSON.parse(errorText)
            errorMessage = error.message || errorMessage
          } catch (parseError) {
            console.error('Failed to parse error response:', parseError)
          }
          throw new Error(errorMessage)
        }

        const blob = new Blob([response.body], {
          type: 'text/csv;charset=utf-8',
        })

        url = window.URL.createObjectURL(blob)
        link = document.createElement('a')
        link.href = url
        link.setAttribute('download', `tasks-export-${moment().format('YYYY-MM-DD')}.csv`)
        document.body.appendChild(link)
        link.click()

        this.$toasted.success('Tasks exported successfully')
        this.closeModal()
      } catch (error) {
        console.error('Export failed:', error)
        this.$toasted.error(error.message || 'Failed to export tasks. Please try again.')
      } finally {
        if (url) window.URL.revokeObjectURL(url)
        if (link && link.parentNode) link.parentNode.removeChild(link)
        this.downloadingCSV = false
      }
    },
    showDownloadConfirmation() {
      this.showDownloadModal = true
      document.addEventListener('keydown', this.handleModalKeydown)
      document.body.classList.add('modal-open')
    },
    getFilters() {
      return {
        query: this.filters.query || undefined,
        externalStatus: this.filters.externalStatus?.filter(Boolean) || undefined,
        product: this.filters.product?.filter(Boolean) || undefined,
        startDate: this.filters.startDate || undefined,
        endDate: this.filters.endDate || undefined,
      }
    },
    closeModal() {
      this.showDownloadModal = false
      this.downloadingCSV = false
      document.removeEventListener('keydown', this.handleModalKeydown)
      document.body.classList.remove('modal-open')
    },
    handleModalKeydown(event) {
      if (event.key === 'Escape') {
        this.closeModal()
      }
    },
    getNestedValue(obj, path) {
      return path.split('.').reduce((current, part) => {
        return current ? current[part] : undefined
      }, obj)
    },
    sortBy(key) {
      if (this.sortKey === key) {
        this.sortAscending = !this.sortAscending
      } else {
        this.sortKey = key
        this.sortAscending = true
      }
    },
    isProductActive(product) {
      if (!this.filters.product || this.filters.product.length === 0) {
        return product === this.productOptions[0]
      }
      return this.filters.product.includes(product)
    },
    async selectProduct(product) {
      const { product: _, ...otherFilters } = this.filters

      const newFilters = {
        ...otherFilters,
        product: [product],
      }

      await this.$store.commit('task/updateFilters', newFilters)

      await this.getTaskList()

      this.$analytics.track({
        event: 'Switch Product Tab',
        payload: { product },
      })
    },
    async initializeDefaultProduct() {
      if (!this.filters.product || this.filters.product.length === 0) {
        const firstProduct = this.productOptions[0]
        await this.$store.commit('task/setFilterProduct', [firstProduct])
      }
      await this.getTaskList()
    },
    onLimitChange() {
      this.$store.dispatch('task/updateLimit', this.limit)
    },
    onPageChange({ page, skip }) {
      this.$store.dispatch('task/changePage', page)
    },
  },
  async created() {
    await this.initializeDefaultProduct()

    this.debouncedIdentifierSearch = debounce(function () {
      const query = this.query
      this.$analytics.track({
        event: 'Search Tasks',
        payload: { query },
      })
      this.updateQueryFilter(query)
    }, 500)
  },
  beforeDestroy() {
    if (this.debouncedIdentifierSearch) {
      this.debouncedIdentifierSearch.cancel()
    }
    if (this.showDownloadModal) {
      document.removeEventListener('keydown', this.handleModalKeydown)
      document.body.classList.remove('modal-open')
    }
  },
}
</script>

<style scoped lang="scss">
.table-responsive {
  margin-bottom: 0;
}

.table {
  &.card-table {
    th {
      font-weight: bold;
    }
    td {
      vertical-align: middle;
    }
  }
}

.modal {
  background-color: rgba(0, 0, 0, 0.5);
  transition: background-color 0.3s ease;

  &:focus {
    outline: none;
  }

  .modal-content {
    &:focus {
      outline: none;
    }
  }

  .btn-close:focus,
  .btn:focus {
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
}

.modal-backdrop {
  opacity: 0.5;
}

.modal-backdrop-enter-active,
.modal-backdrop-leave-active {
  transition: opacity 0.3s ease;
}

.modal-backdrop-enter-from,
.modal-backdrop-leave-to {
  opacity: 0;
}

.modal-enter-active,
.modal-leave-active {
  transition: all 0.3s ease;
}

.modal-enter-from,
.modal-leave-to {
  opacity: 0;
  transform: translateY(-30px);
}

body.modal-open {
  overflow: hidden;
  padding-right: 17px;
}

.input-group-sm {
  min-width: 170px;

  input[type='date'] {
    &::-webkit-calendar-picker-indicator {
      cursor: pointer;
    }
  }
}

.gap-2 {
  gap: 0.5rem !important;
}

.sortable {
  cursor: pointer;
  user-select: none;

  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
  }
}

.table {
  &.card-table {
    th {
      font-weight: bold;
      transition: background-color 0.2s ease;

      svg {
        display: inline-block;
        vertical-align: middle;
        color: #6e84a3;
      }
    }
  }
}

.nav-tabs {
  border-bottom: 1px solid #e3ebf6;

  .nav-link {
    color: #95aac9;
    border: none;
    padding: 1rem 1.5rem;
    font-weight: 500;

    &:hover {
      border: none;
      color: #12263f;
    }

    &.active {
      color: var(--bs-primary);
      border-bottom: 2px solid var(--bs-primary);
    }
  }

  .nav-item {
    margin: 0;

    &.show .nav-link {
      background-color: transparent;
      border-color: transparent transparent var(--bs-primary);
      color: #12263f;
    }
  }

  .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: -1px;
  }
}

.nav-tabs .nav-link:focus,
.nav-tabs .nav-link:hover {
  border-color: transparent;
  isolation: isolate;
}
.nav-tabs .nav-link.disabled {
  background-color: transparent;
  border-color: transparent;
  color: #95aac9;
}
.nav-tabs .nav-item {
  margin: 0;
}
.nav-tabs .nav-item.show .nav-link,
.nav-tabs .nav-link.active {
  background-color: transparent;
  border-color: transparent transparent var(--bs-primary);
  color: #12263f;
}
.nav-tabs .dropdown-menu {
  border-top-left-radius: 0;
  border-top-right-radius: 0;
  margin-top: -1px;
}
.nav-pills .nav-link {
  background: none;
  border: 0;
  border-radius: 0.375rem;
}
.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
  background-color: var(--bs-primary);
  color: #fff;
}
.nav-fill .nav-item,
.nav-fill > .nav-link {
  flex: 1 1 auto;
  text-align: center;
}
.nav-justified .nav-item,
.nav-justified > .nav-link {
  flex-basis: 0;
  flex-grow: 1;
  text-align: center;
}
.nav-fill .nav-item .nav-link,
.nav-justified .nav-item .nav-link {
  width: 100%;
}
.tab-content > .tab-pane {
  display: none;
}
.tab-content > .active {
  display: block;
}
</style>
