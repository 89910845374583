<script>
import { createNamespacedHelpers } from 'vuex'
const { mapState, mapActions } = createNamespacedHelpers('task')
import { createHelpers } from 'vuex-map-fields'
const { mapFields } = createHelpers({
  getterType: 'task/getField',
  mutationType: 'task/updateField',
})

export default {
  computed: {
    ...mapState(['task', 'tasks', 'filters', 'loadingTasks']),
    ...mapFields([
      'newTask.company',
      'newTask.customer',
      'newTask.product',
      'newTask.additionalProduct',
      'newTask.inputs',
      'newTask.sendUserInvite',
      'newTask.settings.transfer',
      'newTask.settings.deposit',
      'newTask.user.identifier',
      'newTask.user.phone',
      'newTask.user.email',
      'newTask.user.names[0].firstName',
      'newTask.user.names[0].lastName',
      'newTask.user.addresses',
      'newTask.user.addresses[0].recipient',
      'newTask.user.addresses[0].line1',
      'newTask.user.addresses[0].line2',
      'newTask.user.addresses[0].city',
      'newTask.user.addresses[0].state',
      'newTask.user.addresses[0].zipcode',
      'newTask.user.accounts[0].accountNumber',
      'newTask.user.accounts[0].routingNumber',
      'newTask.user.accounts[0].type',
      'newTask.user.accounts[0].title',
    ]),
  },
  methods: {
    ...mapActions([
      'getTask',
      'getTaskList',
      'toggleProductFilter',
      'toggleStatusFilter',
      'updateDateRangeFilter',
      'updateQueryFilter',
      'loadMoreTasks',
      'createTask',
    ]),
  },
}
</script>
