<!-- src/components/Common/SortIcon.vue -->
<template>
  <svg
    width="6"
    height="10"
    viewBox="0 0 6 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    :class="{ 'sort-descending': !ascending }"
  >
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z"
      fill="currentColor"
    />
  </svg>
</template>

<script>
export default {
  name: 'SortIcon',
  props: {
    ascending: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style scoped>
.sort-descending {
  transform: rotate(180deg);
}
</style>
