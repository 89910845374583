import { render, staticRenderFns } from "./UserEditRoleModal.vue?vue&type=template&id=56d736f7"
import script from "./UserEditRoleModal.vue?vue&type=script&lang=js"
export * from "./UserEditRoleModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1694635774/src/console/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('56d736f7')) {
      api.createRecord('56d736f7', component.options)
    } else {
      api.reload('56d736f7', component.options)
    }
    module.hot.accept("./UserEditRoleModal.vue?vue&type=template&id=56d736f7", function () {
      api.rerender('56d736f7', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Modules/Settings/SettingsTeam/UserEditRoleModal.vue"
export default component.exports