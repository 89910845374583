var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "card-footer d-flex justify-content-between" },
    [
      _c(
        "ul",
        {
          staticClass:
            "list-pagination-prev pagination pagination-tabs card-pagination",
        },
        [
          _c("li", { staticClass: "page-item" }, [
            _c(
              "a",
              {
                staticClass: "page-link ps-0 pe-4 border-right",
                class: { disabled: !_vm.skip },
                attrs: { "data-test-id": "data-pager-prev", href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.previousPage()
                  },
                },
              },
              [
                _c("ArrowLeftIcon", {
                  staticClass: "me-1",
                  attrs: { size: "16" },
                }),
                _vm._v(" Prev "),
              ],
              1
            ),
          ]),
        ]
      ),
      _vm.showPages
        ? _c(
            "ul",
            {
              staticClass:
                "list-pagination pagination pagination-tabs card-pagination",
            },
            _vm._l(_vm.pages, function (page) {
              return _c(
                "li",
                {
                  key: page,
                  class: {
                    active:
                      _vm.limit * (page - 1) === _vm.skip ||
                      (!_vm.skip && page === 1),
                  },
                  attrs: { "data-test-id": "data-pager-page-" + page },
                },
                [
                  _c(
                    "a",
                    {
                      staticClass: "page",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.preventDefault()
                          return _vm.gotoPage(page)
                        },
                      },
                    },
                    [_vm._v(_vm._s(page))]
                  ),
                ]
              )
            }),
            0
          )
        : _vm._e(),
      _c(
        "ul",
        {
          staticClass:
            "list-pagination-next pagination pagination-tabs card-pagination",
        },
        [
          _c("li", { staticClass: "page-item" }, [
            _c(
              "a",
              {
                staticClass: "page-link ps-4 pe-0 border-left",
                class: {
                  disabled:
                    _vm.totalResults < _vm.skip + _vm.limit && !_vm.showNext,
                },
                attrs: { "data-test-id": "data-pager-next", href: "#" },
                on: {
                  click: function ($event) {
                    $event.preventDefault()
                    return _vm.nextPage()
                  },
                },
              },
              [
                _vm._v(" Next "),
                _c("ArrowRightIcon", {
                  staticClass: "ms-1",
                  attrs: { size: "16" },
                }),
              ],
              1
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }