var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.sampleDataMode
      ? _c(
          "div",
          {
            staticClass:
              "alert alert-primary mb-0 rounded-0 text-center sample-data-notice",
            attrs: { role: "alert" },
          },
          [_vm._v(" " + _vm._s(_vm.noDataText) + " ")]
        )
      : _vm._e(),
    _c(
      "div",
      { staticClass: "nav-header bg-dark pb-5" },
      [
        _c("div", { staticClass: "ms-4 me-4 mb-3" }, [
          _c("div", { staticClass: "header-body" }, [
            _c("div", { staticClass: "row align-items-end" }, [
              _c(
                "div",
                { staticClass: "col d-flex" },
                [_vm._m(0), _c("DateRangeBar")],
                1
              ),
              _c("div", { staticClass: "col-auto" }, [_c("FunnelSettings")], 1),
            ]),
          ]),
        ]),
        _c("Funnel", { staticClass: "mt-0 mb-7" }),
      ],
      1
    ),
    _c(
      "div",
      { staticClass: "mt-n6 container straddle-fold" },
      [
        _c("h4", { staticClass: "text-secondary mb-4" }, [
          _vm._v("Top Connections"),
        ]),
        _c("TopConnections"),
      ],
      1
    ),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-lg-6 col-xl" },
          [_c("AuthenticationRate")],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-lg-6 col-xl" },
          [_c("MonitoredAccounts")],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-lg-6 col-xl" },
          [_c("CompletedTasks")],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-lg-6 col-xl" },
          [_c("UniqueUsers")],
          1
        ),
      ]),
    ]),
    _c("div", { staticClass: "container" }, [
      _c("div", { staticClass: "row" }, [
        _c(
          "div",
          { staticClass: "col-12 col-xl-8" },
          [_c("TransactionVolume")],
          1
        ),
        _c(
          "div",
          { staticClass: "col-12 col-xl-4" },
          [_c("FailureReasons")],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "d-flex gap-2 align-items-center" }, [
      _c("h1", { staticClass: "header-title text-white" }, [
        _vm._v("Activity"),
      ]),
      _c("h5", { staticClass: "m-0" }, [
        _c("span", { staticClass: "badge bg-primary-soft merge-into-header" }, [
          _vm._v("Beta"),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }