import Router from 'vue-router'
import { startCase } from 'lodash-es'
import multiguard from 'vue-router-multiguard'

import { getInstance } from '@/plugins/auth'
import Analytics from '@/plugins/analytics'
import {
  checkRoutePermissions,
  checkRouteState,
  checkRouteScope,
  checkRouteFinishedOnboarding,
} from '@/lib/router-authorization'
import { PERMISSIONS } from '@/lib/permissions'
import { BANNO_STATUSES } from '@/utils/constants'
import { SCOPES } from '@atomicfi/constants-shared'

// Activity
import Activity from './views/Activity/Activity.vue'

// Emulator
import Emulator from './views/Emulator/Emulator.vue'
// Invite Employer

// Tasks
import TaskList from './views/Task/TaskList.vue'
import TaskDetail from './views/Task/TaskDetail.vue'

// Settings
import SettingsGeneral from '@/components/Modules/Settings/SettingsGeneral.vue'
import SettingsTeam from '@/components/Modules/Settings/SettingsTeam.vue'
import SettingsTeamInviteUser from '@/components/Modules/Settings/SettingsTeam/UserAddForm'
import SettingsCredentials from '@/components/Modules/Settings/SettingsCredentials.vue'
import SettingsFeatures from '@/components/Modules/Settings/SettingsFeatures.vue'
import SettingsWebhooks from '@/components/Modules/Settings/SettingsWebhooks.vue'
import SettingsExperiments from '@/components/Modules/Settings/SettingsExperiments.vue'
import SettingsSurveys from '@/components/Modules/Settings/SettingsSurveys.vue'
import SettingsBanno from '@/components/Modules/Settings/SettingsBanno'
import SettingsRolePermissions from '@/components/Modules/Settings/SettingsRolePermissions'

// Resources
import DepositScenarioSimulator from '@/components/Modules/Resources/DepositScenarioSimulator.vue'
import ResourcesFigma from '@/components/Modules/Resources/ResourcesFigma.vue'

const routes = [
  {
    path: '/onboard',
    name: 'onboard',
    component: () => import('./views/Onboard/Onboard.vue'),
    meta: {
      hideNav: true,
    },
  },
  {
    path: '/activity',
    name: 'activity',
    component: Activity,
    meta: {
      hideNav: false,
    },
  },
  // Emulator
  {
    path: '/emulator',
    name: 'emulator',
    component: Emulator,
    meta: {
      hideNav: false,
    },
    beforeEnter: multiguard([checkRoutePermissions([PERMISSIONS.USE_EMULATOR])]),
  },
  // Figma redirect
  {
    path: '/figma',
    beforeEnter: (to, from, next) => next({ name: 'resources-figma' }),
  },
  // Tasks
  {
    // Previously the route for TaskList was /tasks/list.
    path: '/tasks/list',
    redirect: '/',
  },
  {
    path: '/',
    name: 'tasks-list',
    component: TaskList,
    meta: {
      hideNav: false,
    },
    beforeEnter: multiguard([
      checkRouteFinishedOnboarding(),
      // // Temporarily removing SEARCH_TASKS permission guard while we test out
      // // making TaskList the default view instead of Activity.
      // TODO: Remove this comment once we've confirmed that TaskList is the
      // default view.
      // checkRoutePermissions([PERMISSIONS.SEARCH_TASKS]),
    ]),
  },
  {
    path: '/tasks/detail/:id',
    name: 'tasks-detail',
    component: TaskDetail,
    meta: {
      hideNav: false,
    },
    // Temporarily removing VIEW_TASK permission guard while we test out
    // making TaskDetail the default view instead of Activity.
    // TODO: Remove this comment once we've confirmed that TaskDetail is the
    // default view.
    // beforeEnter: multiguard([
    //   checkRoutePermissions([PERMISSIONS.VIEW_TASK]),
    // ]),
  },
  // Resources
  {
    path: '/resources',
    name: 'resources',
    children: [
      {
        path: 'figma',
        name: 'resources-figma',
        component: ResourcesFigma,
        beforeEnter: multiguard([checkRoutePermissions([PERMISSIONS.VIEW_DESIGNS])]),
      },
      {
        path: 'deposit-scenario-simulator',
        name: 'deposit-scenario-simulator',
        component: DepositScenarioSimulator,
      },
    ],
    component: () => import('./views/Resources/Resources.vue'),
  },
  // Customers
  {
    path: '/customers',
    name: 'customers',
    component: () => import('./views/Customers/Customers.vue'),
    props: (route) => ({
      query: route.query.query,
      page: route.query.page,
    }),
  },
  // Settings
  {
    path: '/settings',
    name: 'settings',
    children: [
      {
        path: 'general',
        name: 'settings-general',
        component: SettingsGeneral,
        beforeEnter: multiguard([checkRoutePermissions([PERMISSIONS.MANAGE_INSTITUTION_SETTINGS])]),
      },
      {
        path: 'team',
        name: 'settings-team',
        component: SettingsTeam,
      },
      {
        path: 'permissions',
        name: 'settings-role-permissions',
        component: SettingsRolePermissions,
      },
      {
        path: 'team-invite-user',
        name: 'settings-team-invite',
        component: SettingsTeamInviteUser,
        beforeEnter: multiguard([checkRoutePermissions([PERMISSIONS.ADD_USERS])]),
      },
      {
        path: 'credentials',
        name: 'settings-credentials',
        component: SettingsCredentials,
        beforeEnter: multiguard([checkRoutePermissions([PERMISSIONS.MANAGE_CREDENTIALS])]),
      },
      {
        path: 'features',
        name: 'settings-features',
        component: SettingsFeatures,
        beforeEnter: multiguard([checkRoutePermissions([PERMISSIONS.MANAGE_FEATURES])]),
      },
      {
        path: 'webhooks',
        name: 'settings-webhooks',
        component: SettingsWebhooks,
        beforeEnter: multiguard([checkRoutePermissions([PERMISSIONS.MANAGE_WEBHOOKS])]),
      },
      {
        path: 'experiments',
        name: 'settings-experiments',
        component: SettingsExperiments,
        beforeEnter: multiguard([
          checkRouteState((store) => store.getters['environment/isProduction']),
          checkRoutePermissions([PERMISSIONS.MANAGE_EXPERIMENTS]),
        ]),
      },
      {
        path: 'surveys',
        name: 'settings-surveys',
        component: SettingsSurveys,
        beforeEnter: multiguard([
          checkRouteState((store) => store.getters['environment/isProduction']),
          checkRoutePermissions([PERMISSIONS.MANAGE_SURVEYS]),
        ]),
      },
      {
        path: 'banno',
        name: 'settings-banno',
        component: SettingsBanno,
        beforeEnter: multiguard([
          checkRouteState(
            (store) => store.getters['customer/bannoStatus'] !== BANNO_STATUSES.DISABLED
          ),
          checkRoutePermissions([PERMISSIONS.MANAGE_FEATURES]),
        ]),
      },
    ],
    component: () => import(/* webpackChunkName: "company" */ './views/Settings/Settings.vue'),
  },
  // Signup
  {
    path: '/signup',
    name: 'signup',
    component: () => import('./views/Signup/Signup.vue'),
    meta: { public: true },
  },
]

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  linkExactActiveClass: 'active',
  routes,
})

router.beforeEach((to, from, next) => {
  const authService = getInstance()
  const checkAuthenticatedUser = () => {
    if (authService.isAuthenticated || to.meta?.public) {
      return next()
    }

    authService.loginWithRedirect({ appState: { targetUrl: to.fullPath } })
  }

  // If loading has already finished, check our auth state using `checkAuthenticatedUser()`
  if (!authService.loading) {
    return checkAuthenticatedUser()
  }

  // Watch for the loading property to change before we check isAuthenticated
  authService.$watch('loading', (loading) => {
    if (loading === false) {
      return checkAuthenticatedUser()
    }
  })
})

router.afterEach((to, from) => {
  Analytics.get().track({
    event: `View Page - ${startCase(to.name)}`,
  })
})

export default router
