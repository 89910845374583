import { render, staticRenderFns } from "./EmulatorNavigation.vue?vue&type=template&id=d49e638a&scoped=true"
import script from "./EmulatorNavigation.vue?vue&type=script&lang=js"
export * from "./EmulatorNavigation.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "d49e638a",
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/codebuild/output/src1694635774/src/console/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('d49e638a')) {
      api.createRecord('d49e638a', component.options)
    } else {
      api.reload('d49e638a', component.options)
    }
    module.hot.accept("./EmulatorNavigation.vue?vue&type=template&id=d49e638a&scoped=true", function () {
      api.rerender('d49e638a', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/Modules/Emulator/EmulatorNavigation.vue"
export default component.exports