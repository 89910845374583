var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this
    var _h = _vm.$createElement
    var _c = _vm._self._c || _h
    return _c("div", { staticClass: "card-body text-center" }, [
      _c("div", { staticClass: "row justify-content-center" }, [
        _c("div", { staticClass: "col-12 col-xl-10" }, [
          _c("img", {
            staticClass: "img-fluid mt-5 mb-5",
            staticStyle: { "max-width": "400px" },
            attrs: {
              src: "/images/illustrations/tasks-offstate.svg",
              alt: "...",
            },
          }),
          _c("h2", [_vm._v("No tasks found.")]),
          _c("p", { staticClass: "text-muted" }, [
            _vm._v("Try adjusting your search settings."),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }