var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _vm.task._id
        ? _c(
            "Wrapper",
            {
              attrs: {
                title:
                  _vm.productTitle[0].toUpperCase() +
                  _vm.productTitle.substring(1),
                pretitle: _vm.task.user.identifier,
                image: "/images/icon-" + _vm.task.product + ".svg",
                imageTitle: _vm.task.product,
              },
            },
            [
              _c(
                "a",
                {
                  staticClass: "btn btn-link mb-3",
                  attrs: { href: "#" },
                  on: {
                    click: function ($event) {
                      $event.preventDefault()
                      return _vm.navigateBack.apply(null, arguments)
                    },
                  },
                },
                [_vm._v(" ← Back to all tasks ")]
              ),
              _c("TaskOverview"),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }