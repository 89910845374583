var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "tr",
    {
      staticClass: "clickable-row",
      attrs: {
        tabindex: "0",
        role: "button",
        "aria-label":
          "View " +
          _vm.productTitle +
          " task for user " +
          _vm.task.user.identifier,
      },
      on: {
        click: _vm.navigateToTaskDetail,
        keydown: [
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.navigateToTaskDetail.apply(null, arguments)
          },
          function ($event) {
            if (
              !$event.type.indexOf("key") &&
              _vm._k($event.keyCode, "space", 32, $event.key, [" ", "Spacebar"])
            ) {
              return null
            }
            $event.preventDefault()
            return _vm.navigateToTaskDetail.apply(null, arguments)
          },
        ],
      },
    },
    [
      _c("td", [
        _c(
          "div",
          { staticClass: "d-flex align-items-center gap-2" },
          [
            _c("small", { staticClass: "text-muted" }, [
              _vm._v(
                _vm._s(_vm._f("moment")(_vm.task.createdAt, "from", "now"))
              ),
            ]),
            _vm.isTestTask
              ? _c("BoxIcon", {
                  directives: [
                    {
                      name: "tooltip",
                      rawName: "v-tooltip",
                      value: "Test task",
                      expression: "`Test task`",
                    },
                  ],
                  staticClass: "d-block text-muted",
                  attrs: { size: "18" },
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _c("td", [_vm._v(" " + _vm._s(_vm.task.user.identifier) + " ")]),
      _c(
        "td",
        [
          _c("TaskStatus", {
            directives: [
              {
                name: "tooltip",
                rawName: "v-tooltip",
                value: _vm.taskFailReasonDescription,
                expression: "taskFailReasonDescription",
              },
            ],
            attrs: { task: _vm.task },
          }),
        ],
        1
      ),
      _c("td", [_vm._v(" " + _vm._s(_vm.task.company.name || "-") + " ")]),
      _c("td", [_vm._v(" " + _vm._s(_vm.task.connector.name || "-") + " ")]),
      _vm.isDepositProduct
        ? _c("td", [
            _c("span", { staticClass: "badge capitalize bg-light" }, [
              _vm._v(" " + _vm._s(_vm.distributionType || "-") + " "),
            ]),
          ])
        : _vm._e(),
      _vm.isDepositProduct
        ? _c("td", [
            _vm.distributionType !== "-"
              ? _c("span", [
                  _vm.hasDistributionType("total")
                    ? _c("span", { staticClass: "badge bg-light" }, [
                        _vm._v(" Entire Balance "),
                      ])
                    : _vm._e(),
                  _vm.hasDistributionType("percent")
                    ? _c("span", { staticClass: "badge bg-light" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm.task.settings.deposit.distributionAmount
                            ) +
                            "% "
                        ),
                      ])
                    : _vm._e(),
                  _vm.hasDistributionType("fixed")
                    ? _c("span", { staticClass: "badge bg-light" }, [
                        _vm._v(
                          " " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.task.settings.deposit.distributionAmount
                              )
                            ) +
                            " "
                        ),
                      ])
                    : _vm._e(),
                ])
              : _c("span", [_vm._v("-")]),
          ])
        : _vm._e(),
      _c("td", [
        _vm.showBannoPeopleLink
          ? _c(
              "a",
              {
                directives: [
                  {
                    name: "tooltip",
                    rawName: "v-tooltip",
                    value: "View on Banno People",
                    expression: "'View on Banno People'",
                  },
                ],
                staticClass: "btn btn-sm btn-outline-secondary me-2",
                attrs: {
                  href:
                    "https://banno.com/a/people/" +
                    _vm.bannoShortId +
                    "/users/" +
                    _vm.task.user.identifier,
                  target: "_blank",
                  "aria-label":
                    "View User " +
                    _vm.task.user.identifier +
                    " on Banno People",
                },
                on: {
                  click: function ($event) {
                    $event.stopPropagation()
                  },
                },
              },
              [
                _vm._v(" View User "),
                _c("ExternalLinkIcon", {
                  staticClass: "ms-2 align-middle",
                  attrs: { size: "14" },
                }),
              ],
              1
            )
          : _vm._e(),
        _c(
          "a",
          {
            staticClass: "btn btn-sm btn-outline-secondary",
            attrs: {
              "aria-label":
                "View " +
                _vm.productTitle +
                " task for user " +
                _vm.task.user.identifier,
            },
            on: {
              click: [
                _vm.navigateToTaskDetail,
                function ($event) {
                  $event.stopPropagation()
                },
              ],
            },
          },
          [_vm._v(" View Task ")]
        ),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }