<template>
  <div class="card-footer d-flex justify-content-between">
    <!-- Pagination (prev) -->
    <ul class="list-pagination-prev pagination pagination-tabs card-pagination">
      <li class="page-item">
        <a
          data-test-id="data-pager-prev"
          class="page-link ps-0 pe-4 border-right"
          :class="{ disabled: !skip }"
          @click.prevent="previousPage()"
          href="#"
        >
          <ArrowLeftIcon size="16" class="me-1" /> Prev
        </a>
      </li>
    </ul>

    <!-- Pagination -->
    <ul v-if="showPages" class="list-pagination pagination pagination-tabs card-pagination">
      <li
        :data-test-id="'data-pager-page-' + page"
        :class="{ active: limit * (page - 1) === skip || (!skip && page === 1) }"
        v-for="page in pages"
        :key="page"
      >
        <a class="page" href="#" @click.prevent="gotoPage(page)">{{ page }}</a>
      </li>
    </ul>
    <!-- Pagination (next) -->
    <ul class="list-pagination-next pagination pagination-tabs card-pagination">
      <li class="page-item">
        <a
          data-test-id="data-pager-next"
          class="page-link ps-4 pe-0 border-left"
          @click.prevent="nextPage()"
          href="#"
          :class="{ disabled: totalResults < skip + limit && !showNext }"
        >
          Next
          <ArrowRightIcon size="16" class="ms-1" />
        </a>
      </li>
    </ul>
  </div>
</template>

<script>
import { ArrowLeftIcon, ArrowRightIcon } from 'vue-feather-icons'
export default {
  name: 'DataPager',
  components: { ArrowLeftIcon, ArrowRightIcon },
  props: {
    limit: {
      type: Number,
      default: 25,
    },
    totalResults: {
      type: Number,
      default: 0,
    },
    showNext: {
      type: Boolean,
    },
    initialSkip: {
      type: Number,
      default: 0,
    },
    showPages: {
      type: Boolean,
      default: true,
    },
  },
  data: function () {
    return {
      page: 1,
      skip: this.initialSkip,
    }
  },
  watch: {
    initialSkip: function (newVal) {
      this.skip = newVal
    },
  },
  computed: {
    pages() {
      let options = []
      const maxOptions = 8
      if (
        maxOptions * this.limit < this.totalResults &&
        this.skip * this.limit > maxOptions * this.limit
      )
        options.push(this.skip / this.limit)

      while (
        options.length < maxOptions &&
        (!options.length || options[options.length - 1] * this.limit < this.totalResults)
      ) {
        options.push(options[0] ? options[options.length - 1] + 1 : 1)
      }
      return options
    },
  },
  methods: {
    gotoPage(page) {
      this.page = page
      this.setSkip(Math.max(0, (page - 1) * this.limit))
    },
    nextPage() {
      this.page = Math.min(this.pages.length, this.page + 1)
      this.setSkip(this.skip + this.limit)
    },
    previousPage() {
      this.page = Math.max(1, this.page - 1)
      this.setSkip(Math.max(0, this.skip - this.limit))
    },
    setSkip(skip) {
      this.skip = skip
      this.$emit('change', { page: this.page, skip })
    },
  },
}
</script>

<style scoped lang="scss">
.disabled {
  opacity: 0;
}
</style>
