<template>
  <Wrapper
    title="Emulator"
    :fullscreen="true"
    :class="{ dark: settings.theme.dark, fullscreen: fullscreenMode }"
  >
    <div class="card-group full-height row p-0 m-0">
      <div
        class="col-12 col-md-3 m-0 p-0 panel-config panel-scrollable shadow-lg"
        :class="{ fullscreen: fullscreenMode }"
      >
        <div class="d-flex justify-content-center">
          <EmulatorScopeSelector
            v-if="!scope"
            class="align-self-center"
            @selectedScope="getOrCreateAccessToken"
          />
        </div>
        <div class="panel-config-wrap">
          <div class="card-body" v-if="scope">
            <transition :name="activeNavItem ? 'push-right' : 'push-left'" mode="out-in">
              <div v-if="activeNavItem" key="back-button" class="mb-3">
                <a href="#" @click="goBack" class="back-link">
                  <ArrowLeftIcon size="24" class="mb-1 d-inline-block success" />
                  Transact SDK
                </a>
              </div>
              <div v-else key="header" class="mt-2">
                <h3>Transact SDK</h3>
              </div>
            </transition>
            <transition :name="activeNavItem ? 'push-right' : 'push-left'" mode="out-in">
              <EmulatorNavigation v-if="!activeNavItem" key="nav" />
              <EmulatorInitialization
                key="initialization"
                v-else-if="activeNavItem === 'initialization'"
              />
              <EmulatorStyles key="theme" v-else-if="activeNavItem === 'styles'" />
              <EmulatorFonts key="fonts" v-else-if="activeNavItem === 'fonts'" />
              <EmulatorDemo key="demo" v-else-if="activeNavItem === 'demo' && demoIsAvailable" />
              <EmulatorLogin key="login" v-else-if="activeNavItem === 'login'" />
              <EmulatorMutationReview key="mutations" v-else-if="activeNavItem === 'mutations'" />
              <EmulatorScreenCustomization
                v-else-if="scope.name === scopeOptions.USER_LINK"
                :name="activeNavItem"
                :key="activeNavItem"
              />
            </transition>
          </div>

          <transition name="push-up" mode="out-in">
            <button
              v-if="scope && (activeNavItem === 'initialization' || !isInitialized)"
              :disabled="disableInitialization"
              class="btn btn-primary btn-large btn-fixed col-12 mt-3 action-button"
              @click="launchTransact"
              key="btn-initialize"
            >
              Initialize
            </button>
            <button
              v-else-if="showReviewCustomizationsButton"
              class="btn btn-primary btn-large btn-fixed col-12 mt-3 action-button"
              @click="reviewCustomizations"
              key="btn-review-customizations"
            >
              Review Customizations
              <span class="badge bg-light">{{ pendingMutations.length }}</span>
            </button>
            <button
              v-else-if="showSaveChangesSandboxButton"
              @click="commitMutations([activeEnvironment])"
              class="btn btn-primary btn-large btn-fixed col-12 mt-3 action-button"
              key="btn-save-changes-sandbox"
            >
              Publish to Sandbox
            </button>
            <button
              v-else-if="showSaveChangesButton"
              v-b-modal="'select-environment-modal'"
              class="btn btn-primary btn-large btn-fixed col-12 mt-3 action-button"
              key="btn-save-changes"
            >
              Publish
            </button>
          </transition>
          <EmulatorSelectEnvironmentModal @submit="commitMutations" />
        </div>
      </div>
      <div
        class="d-none d-md-block col-7 m-0 p-0 panel-viewport d-flex"
        :class="{
          'panel-viewport-dark': settings.theme.dark,
          disabled: !scope,
          fullscreen: fullscreenMode,
        }"
      >
        <div class="transact-container-wrapper text-center">
          <div class="transact-container">
            <button
              v-if="!session"
              :disabled="disableInitialization"
              @click="launchTransact"
              class="session-empty-state"
            >
              <img :src="preLaunchMockupSvgUrl" />
            </button>
            <div id="transact-iframe"></div>
          </div>
          <EmulatorColorModeSelector class="mt-4" />
          <div class="emulator-toggles">
            <a
              @click="enableAssistance = !enableAssistance"
              class="btn btn-sm mt-4"
              :class="{
                'text-muted': !enableAssistance,
                'btn-outline-white opacity-50': settings.theme.dark,
                'btn-outline-secondary': !settings.theme.dark,
              }"
              v-tooltip="
                'Autopilot automatically navigates to the configuration options relevant to your current screen.'
              "
            >
              <CheckIcon v-if="enableAssistance" size="14" class="mt-1" />
              {{ enableAssistance ? 'Autopilot' : 'Autopilot disabled' }}
            </a>
            <a
              @click="toggleFullscreen"
              class="btn btn-sm mt-4"
              :class="{
                'text-muted': !enableAssistance,
                'btn-outline-white opacity-50': settings.theme.dark,
                'btn-outline-secondary': !settings.theme.dark,
              }"
              v-tooltip="'Hides the Console interface for better showcasing Transact.'"
            >
              <MaximizeIcon size="14" class="mt-1" />
              {{ fullscreenMode ? 'Exit Fullscreen' : 'Fullscreen' }}
            </a>
          </div>
        </div>
      </div>
      <div
        class="d-none d-xl-block col-3 m-0 p-0 event-list panel-events panel-scrollable"
        :class="{ disabled: !scope, fullscreen: fullscreenMode }"
      >
        <EmulatorEvents
          class="col-12 m-0 p-0 event-list"
          :events="events"
          v-on:clear="clearEvents"
        />
      </div>
    </div>
  </Wrapper>
</template>

<script>
import { ArrowLeftIcon, MaximizeIcon, CheckIcon } from 'vue-feather-icons'
import { Atomic } from '@atomicfidev/transact-javascript'
import { cloneDeep, get, pick } from 'lodash-es'
import { emitEmulatorEvent, requestDataFromTransact } from '@/utils/emulator'
import { EMULATOR_EVENT_TYPES, DEEPLINK_STEPS, DISTRIBUTION_TYPES } from '@/utils/constants'
import { featureUniqueForAtomic } from '@/lib/customer-customizations'
import { hasPermissions, hasProductionAccess } from '@/lib/authorization'
import { mapActions, mapGetters, mapMutations, mapState } from 'vuex'
import { PERMISSIONS } from '@/lib/permissions'
import { PRODUCTS, SCOPES } from '@atomicfi/constants-shared'
import EmulatorColorModeSelector from '@/components/Modules/Emulator/EmulatorColorModeSelector'
import EmulatorDemo from '@/components/Modules/Emulator/EmulatorDemo'
import EmulatorEvents from '@/components/Modules/Emulator/EmulatorEvents'
import EmulatorFonts from '@/components/Modules/Emulator/EmulatorFonts'
import EmulatorInitialization from '@/components/Modules/Emulator/EmulatorInitialization'
import EmulatorScopeSelector from '@/components/Modules/Emulator/EmulatorScopeSelector'
import EmulatorLogin from '@/components/Modules/Emulator/EmulatorLogin'
import EmulatorMutationReview from '@/components/Modules/Emulator/EmulatorMutationReview'
import EmulatorNavigation from '@/components/Modules/Emulator/EmulatorNavigation'
import EmulatorScreenCustomization from '@/components/Modules/Emulator/EmulatorScreenCustomization'
import EmulatorSelectEnvironmentModal from './EmulatorSelectEnvironmentModal.vue'
import EmulatorStyles from '@/components/Modules/Emulator/EmulatorStyles'
import UserService from '@/services/UserService'

export default {
  name: 'Emulator',
  data: () => ({
    events: [],
    enableAssistance: true,
    showRelaunchButton: false,
    distribution: {
      type: DISTRIBUTION_TYPES.TOTAL,
      amount: undefined,
      action: 'create',
    },
    transactIframeElement: '#transact-iframe',
    scopeOptions: undefined,
  }),
  components: {
    EmulatorColorModeSelector,
    ArrowLeftIcon,
    EmulatorEvents,
    EmulatorInitialization,
    EmulatorLogin,
    EmulatorFonts,
    EmulatorDemo,
    EmulatorStyles,
    EmulatorMutationReview,
    EmulatorNavigation,
    EmulatorScreenCustomization,
    EmulatorSelectEnvironmentModal,
    EmulatorScopeSelector,
    CheckIcon,
    MaximizeIcon,
  },
  computed: {
    ...mapState('customer', ['customer', 'activeCustomer']),
    ...mapState('emulator', [
      'activeNavItem',
      'deeplink',
      'fullscreenMode',
      'pendingMutations',
      'products',
      'session',
      'scope',
      'settings',
      'useMultipleAccounts',
      'useUplinkDemo',
      'taxForms',
    ]),
    ...mapGetters('emulator', ['isInitialized']),
    ...mapState('environment', ['activeEnvironment']),
    disableInitialization() {
      const noProductsSelected = this.products.length === 0
      const deeplinkInvalid = this.deeplink?.step === 'login-company' && !this.deeplink.companyId

      return (
        (!this.showRelaunchButton && this.isInitialized) ||
        noProductsSelected ||
        deeplinkInvalid ||
        !this.scope
      )
    },
    showReviewCustomizationsButton() {
      return (
        this.activeNavItem !== 'initialization' &&
        this.activeNavItem !== 'mutations' &&
        this.pendingMutations.length &&
        hasPermissions(PERMISSIONS.SAVE_TRANSACT_CUSTOMIZATION)
      )
    },
    showSaveChangesButton() {
      return (
        this.activeNavItem === 'mutations' &&
        hasPermissions(PERMISSIONS.SAVE_TRANSACT_CUSTOMIZATION)
      )
    },
    showSaveChangesSandboxButton() {
      return this.showSaveChangesButton && !hasProductionAccess()
    },
    demoIsAvailable() {
      return featureUniqueForAtomic()
    },
    productsIncludeDeposit() {
      return this.products.includes(PRODUCTS.DEPOSIT)
    },
    useAutopilot() {
      return !!(
        this.enableAssistance && [SCOPES.USER_LINK, SCOPES.PAY_LINK].includes(this.scope.name)
      )
    },
    preLaunchMockupSvgUrl() {
      const product = this.products[0] || 'default'
      const themeSuffix = this.settings.theme.dark ? '-dark' : ''
      return `/images/emulator/pre-launch-mockup-${product}${themeSuffix}.svg`
    },
  },
  methods: {
    ...mapMutations('emulator', [
      'setActiveNavItem',
      'resetPendingMutations',
      'setSession',
      'setFullscreenMode',
    ]),
    ...mapActions('customer', ['getAccessToken', 'updateCustomerTransactConfiguration']),
    clearEvents() {
      this.events = []
    },
    toggleFullscreen() {
      this.setFullscreenMode(!this.fullscreenMode)
    },
    closeTransact() {
      if (this.session) this.session.close()
      this.setActiveNavItem('initialization')
      this.setSession(undefined)

      const transactIframe = document.querySelector(this.transactIframeElement)

      if (transactIframe) {
        transactIframe.innerHTML = ''
      }

      this.$analytics.track({
        event: 'Close Emulator',
      })
    },
    goBack() {
      this.setActiveNavItem(undefined)

      this.$analytics.track({
        event: 'Click Back to Emulator Menu',
      })
    },
    async launchTransact() {
      this.clearEvents()
      this.closeTransact()
      const rawSettings = await this.gatherSettings()
      const cleanSettings = this.cleanSettings(rawSettings)

      if (this.productsIncludeDeposit) {
        await this.updateUserAccounts()
      }

      const session = Atomic.transact({
        config: cleanSettings,
        environmentOverride: process.env.VUE_APP_ATOMIC_TRANSACT,
        container:
          cleanSettings.theme.display === 'inline' ? this.transactIframeElement : undefined,
        onFinish: this.onFinish,
        onClose: this.onClose,
        onInteraction: this.onInteraction,
        onDataRequest: this.onDataRequest,
      })
      this.setSession(session)

      this.$analytics.track({
        event: 'Launch Emulator',
      })
    },
    async updateUserAccounts() {
      const accounts = [
        {
          accountNumber: '220000000',
          routingNumber: '110000000',
          type: 'checking',
          title: 'Premier Plus Checking',
        },
      ]

      if (this.useMultipleAccounts) {
        accounts.push({
          accountNumber: '220000001',
          routingNumber: '110000000',
          type: 'savings',
          title: 'Premier Plus Savings',
        })
      }

      await UserService.updateUserAccounts(accounts)
    },
    async getOrCreateAccessToken() {
      const localStorageKeySuffix = `customer-${this.activeCustomer._id}_${this.activeEnvironment}`
      const storedPublicTokenKey = `storedPublicToken_${localStorageKeySuffix}`
      const storedPublicTokenExpirationKey = `storedPublicTokenExpiration_${localStorageKeySuffix}`
      const storedPublicToken = localStorage.getItem(storedPublicTokenKey)
      const storedPublicTokenExpiration = localStorage.getItem(storedPublicTokenExpirationKey)

      // If there is already an unexpired token, then use it instead of generating a new one
      if (
        this.scope.name !== SCOPES.PAY_LINK &&
        storedPublicToken &&
        this.$moment().diff(this.$moment(storedPublicTokenExpiration), 'hours') < 24
      ) {
        return storedPublicToken
      } else {
        const response = await this.getAccessToken({
          queryStringParams: { scope: this.scope.name },
        })
        const { publicToken } = response.data.data

        localStorage.setItem(storedPublicTokenExpirationKey, this.$moment().toISOString())
        localStorage.setItem(storedPublicTokenKey, publicToken)

        return publicToken
      }
    },
    async gatherSettings() {
      let settings = cloneDeep(this.settings)
      settings.scope = this.scope.name

      // If we are on a smaller device, launch in a modal instead
      if (window.matchMedia('(max-width: 768px)').matches) {
        delete settings.theme.display
        settings.theme.overlayColor = 'rgb(144, 144, 144, 0.65)'
      } else {
        settings.theme.overlayColor = '#FFF'
        settings.theme.display = 'inline'
      }

      if (this.useUplinkDemo) {
        settings.demo = { enabled: true, uplink: true }
        settings.platform = { name: 'ios', sdkVersion: '3.0.0' }
      }

      if (this.deeplink.step) {
        settings.deeplink = this.deeplink
      } else {
        delete settings.deeplink
      }
      if (this.distribution.type) settings.distribution = this.distribution

      settings.publicToken = await this.getOrCreateAccessToken()

      return settings
    },
    async commitMutations(environmentsToUpdate) {
      const payload = this.pendingMutations.map((mutation) =>
        pick(mutation, ['action', 'id', 'value'])
      )

      try {
        await this.updateCustomerTransactConfiguration({ payload, environmentsToUpdate })

        this.$analytics.track({
          event: 'Publish Emulator Customizations',
          payload: {
            numberOfCustomizations: payload.length,
          },
        })

        this.$toasted.success('Your changes were saved successfully!')
        this.setActiveNavItem(undefined)
        this.resetPendingMutations()
        this.launchTransact()
      } catch (error) {
        this.$toasted.error('There was an error saving your changes.')
      }
    },
    cleanSettings(settings) {
      settings.tasks = []

      this.products.forEach((product) => {
        settings.tasks.push({ product, ...(product === 'tax' && { forms: this.taxForms }) })
      })

      if (get(settings.distribution, 'type') === DISTRIBUTION_TYPES.TOTAL) {
        delete settings.distribution
      }
      if (get(settings.deeplink, 'step') === DEEPLINK_STEPS.SEARCH_COMPANY) {
        delete settings.deeplink.companyName
        delete settings.deeplink.companyId
        delete settings.deeplink.connectorId
      } else if (get(settings.deeplink, 'step') === DEEPLINK_STEPS.SEARCH_PAYROLL) {
        delete settings.deeplink.companyId
        delete settings.deeplink.connectorId
      } else if (get(settings.deeplink, 'step') === DEEPLINK_STEPS.LOGIN_COMPANY) {
        delete settings.deeplink.companyName
        delete settings.deeplink.connectorId
      } else if (get(settings.deeplink, 'step') === DEEPLINK_STEPS.LOGIN_PAYROLL) {
        delete settings.deeplink.companyId
      } else {
        settings.deeplink = undefined
      }
      return settings
    },
    generateEvent(category, payload) {
      return { category, payload, created: new Date(), guid: this.uuidv4() }
    },
    onFinish: function (payload) {
      this.events.push(this.generateEvent('finish', payload))
      this.closeTransact()
    },
    onClose: function (payload) {
      this.events.push(this.generateEvent('close', payload))
      this.closeTransact()
    },
    onInteraction: function (payload) {
      this.events.push(this.generateEvent('interaction', payload))

      if (this.useAutopilot) {
        this.provideAssistance(this.scope.name, payload)
      }
    },
    onDataRequest: function (payload) {
      this.events.push(this.generateEvent('data-request', payload))
    },
    provideAssistance(scope, { name: interactionName, ...interaction }) {
      if (scope === SCOPES.PAY_LINK) {
        switch (interactionName) {
          case 'Viewed Login Page':
            this.setActiveNavItem('login')
            break
        }
      } else {
        switch (interactionName) {
          case 'Viewed Search By Company Page':
            this.setActiveNavItem('search')
            break
          case 'Viewed Login Page':
          case 'Viewed Login Recovery Page':
            this.setActiveNavItem('login')
            break
          case 'Clicked Continue From Form On Login Recovery Page':
            requestDataFromTransact({
              eventType: EMULATOR_EVENT_TYPES.GET_DATA_FROM_VUEX,
              payload: {
                pathToVuexData: 'currentStep.type',
                namespace: 'formFlow',
                storeActionPath: 'emulator/updateLoginInputType',
              },
            })
            break
          case 'Viewed Welcome Page':
            this.setActiveNavItem('welcome')
            break
          case 'Viewed Distribution Confirmation Page':
          case 'Clicked Never Mind From Select From Deposit Options Page':
          case 'Clicked Continue From Percentage Deposit Amount Page':
          case 'Clicked Continue From Fixed Deposit Amount Page':
          case 'Closed Distribution Amount Modal':
            this.setActiveNavItem('distribution-confirmation')
            break
          case 'Viewed Select From Deposit Options Page':
            this.setActiveNavItem('distribution-setup')
            break
          case 'Clicked Button To Start Authentication':
            this.setActiveNavItem('progress')
            break
          case 'Exit Confirmation Page':
            this.setActiveNavItem('exit-confirmation')
            break
          case 'Viewed High Latency Page':
            this.setActiveNavItem('high-latency')
            break
          case 'Viewed Task Completed Page':
            this.setActiveNavItem('success')
            break
        }
      }

      if (
        interactionName === 'Clicked Continue From Select From Deposit Options Page' &&
        interaction.value?.depositOption === DISTRIBUTION_TYPES.TOTAL
      ) {
        this.setActiveNavItem('distribution-confirmation')
      }
    },
    reviewCustomizations() {
      this.setActiveNavItem('mutations')

      this.$analytics.track({
        event: 'Review Emulator Customizations',
      })
    },
    uuidv4() {
      return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, function (c) {
        var r = (Math.random() * 16) | 0,
          v = c == 'x' ? r : (r & 0x3) | 0x8
        return v.toString(16)
      })
    },
  },
  watch: {
    'settings.theme.dark': function () {
      emitEmulatorEvent({
        type: EMULATOR_EVENT_TYPES.UPDATE_DARK_MODE,
        payload: {
          value: this.settings.theme.dark,
        },
      })
    },
    'settings.language': function () {
      emitEmulatorEvent({
        type: EMULATOR_EVENT_TYPES.UPDATE_LANGUAGE,
        payload: {
          value: this.settings.language,
        },
      })
    },
    settings: {
      handler(newValue, oldValue) {
        this.showRelaunchButton = true
      },
      deep: true,
    },
    products: {
      handler(newValue, oldValue) {
        this.showRelaunchButton = true
      },
      deep: true,
    },
    isInitialized: {
      handler(newValue, oldValue) {
        if (!newValue) this.setActiveNavItem('initialization')
      },
      deep: true,
    },
    deeplink: {
      handler(newValue, oldValue) {
        this.showRelaunchButton = true
      },
      deep: true,
    },
  },
  mounted() {
    let myImage = new Image()
    myImage.src = '/images/emulator/pre-launch-mockup-dark.svg'
    this.scopeOptions = SCOPES
  },
  destroyed() {
    this.closeTransact()
  },
}
</script>

<style scoped lang="scss">
.card {
  border: 0px;
  box-shadow: none;
}

#accordion .card {
  margin-bottom: 0;
  border-radius: 0px;

  &:last-child {
    margin-bottom: 1.5rem;
  }
}

.full-height {
  height: calc(100vh - 70px);
}

.panel-scrollable {
  overflow-y: scroll;
  max-height: 100%;
  position: relative;
  transition: all 300ms ease-in-out;
}

.panel-config {
  background-color: #fff;

  &.fullscreen {
    transform: translateX(-100%);
  }
}

.panel-events {
  background-color: #fff;
  border-radius: 0px !important;
  border-left: 1px solid;
  border-color: var(--bs-light);
  padding: 0px;

  &.fullscreen {
    transform: translateX(100%);
  }

  .event-list {
    padding: 0px !important;
  }

  &.disabled {
    .event-list {
      display: none;
    }
  }
}

.panel-viewport {
  flex: 1;
  overflow-y: auto;
  position: relative;
  transition: all 300ms ease;

  &.disabled {
    opacity: 0.4;
  }
  &.panel-viewport-dark {
    .transact-container-wrapper {
      .transact-container {
        box-shadow: rgba(0, 0, 0, 0.2) 0px 8px 24px !important;
        background-color: #0b0e1e;
      }
    }
  }
  .transact-container-wrapper {
    position: absolute;
    left: 50%;
    top: 5%;
    -ms-transform: translateY(-50%);
    transform: translateY(-50%);
    -ms-transform: translateX(-50%);
    transform: translateX(-50%);

    .transact-container {
      background-color: #fff;
      overflow: hidden;
      width: 375px;
      height: 730px;
      border-radius: 20px;
      box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
      margin: 0;
      position: relative;
    }
  }
}

.main-content {
  background-color: var(--bs-light);

  &.fullscreen {
    transition: background-color 300ms ease;
    overflow: hidden;
  }
  &.dark {
    background-color: #21253d;
  }
}

#transact-iframe {
  width: 100%;
  height: 100%;
}

.session-empty-state {
  align-content: center;
  background: transparent;
  border: none;
  height: 100%;
  opacity: 0.6;
  padding: 0;
  transition: all 0.3s;
  width: 100%;

  &:not([disabled]) {
    cursor: pointer;

    &:hover {
      opacity: 1;
    }
  }
}

.back-link {
  font-weight: 700;
  font-size: 14px;
}

.color-mode-selector-wrapper {
  left: 1rem;
  position: absolute;
  top: 1rem;
  z-index: 10;
}

.emulator-toggles {
  display: flex;
  align-content: center;
  justify-content: center;
  gap: 8px;
}

.action-button {
  // display on top of Dropzone components
  z-index: 1000;
}
</style>
