var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("ul", { staticClass: "navbar-nav me-lg-auto" }, [
    _vm.canViewTasks
      ? _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link",
                class: { active: _vm.isActive(["/", "tasks"]) },
                attrs: { to: "/" },
              },
              [_vm._v(" Tasks ")]
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "li",
      { staticClass: "nav-item" },
      [
        _c(
          "router-link",
          {
            staticClass: "nav-link",
            class: { active: _vm.isActive("activity") },
            attrs: { to: "/activity" },
          },
          [_vm._v(" Activity ")]
        ),
      ],
      1
    ),
    _vm.hasPermissions(_vm.PERMISSIONS.USE_EMULATOR)
      ? _c(
          "li",
          { staticClass: "nav-item" },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link",
                class: { active: _vm.isActive("emulator") },
                attrs: { to: "/emulator" },
              },
              [_vm._v(" Emulator ")]
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "li",
      { staticClass: "nav-item" },
      [
        _c(
          "router-link",
          {
            staticClass: "nav-link",
            class: { active: _vm.isActive("resources") },
            attrs: { to: { name: "resources-figma" } },
          },
          [_vm._v(" Resources ")]
        ),
      ],
      1
    ),
    _vm.canManageChildCustomers
      ? _c(
          "li",
          {
            staticClass: "nav-item",
            attrs: { "data-test-id": "nav-customers" },
          },
          [
            _c(
              "router-link",
              {
                staticClass: "nav-link",
                class: { active: _vm.isActive("customers") },
                attrs: { to: "/customers" },
              },
              [_vm._v(" Customers ")]
            ),
          ],
          1
        )
      : _vm._e(),
    _c(
      "li",
      { staticClass: "nav-item" },
      [
        _c(
          "router-link",
          {
            staticClass: "nav-link",
            class: { active: _vm.isActive("settings") },
            attrs: { to: _vm.settingsRoute },
          },
          [_vm._v(" Settings ")]
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }