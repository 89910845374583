<template>
  <tr
    @click="navigateToTaskDetail"
    @keydown.enter.prevent="navigateToTaskDetail"
    @keydown.space.prevent="navigateToTaskDetail"
    tabindex="0"
    role="button"
    class="clickable-row"
    :aria-label="`View ${productTitle} task for user ${task.user.identifier}`"
  >
    <td>
      <div class="d-flex align-items-center gap-2">
        <small class="text-muted">{{ task.createdAt | moment('from', 'now') }}</small>
        <BoxIcon v-if="isTestTask" size="18" class="d-block text-muted" v-tooltip="`Test task`" />
      </div>
    </td>

    <td>
      {{ task.user.identifier }}
    </td>

    <td>
      <TaskStatus v-tooltip="taskFailReasonDescription" :task="task" />
    </td>

    <td>
      {{ task.company.name || '-' }}
    </td>

    <td>
      {{ task.connector.name || '-' }}
    </td>

    <td v-if="isDepositProduct">
      <span class="badge capitalize bg-light">
        {{ distributionType || '-' }}
      </span>
    </td>

    <td v-if="isDepositProduct">
      <span v-if="distributionType !== '-'">
        <span class="badge bg-light" v-if="hasDistributionType('total')"> Entire Balance </span>
        <span class="badge bg-light" v-if="hasDistributionType('percent')">
          {{ task.settings.deposit.distributionAmount }}%
        </span>
        <span class="badge bg-light" v-if="hasDistributionType('fixed')">
          {{ task.settings.deposit.distributionAmount | currency }}
        </span>
      </span>
      <span v-else>-</span>
    </td>

    <td>
      <a
        v-if="showBannoPeopleLink"
        :href="`https://banno.com/a/people/${bannoShortId}/users/${task.user.identifier}`"
        class="btn btn-sm btn-outline-secondary me-2"
        target="_blank"
        :aria-label="`View User ${task.user.identifier} on Banno People`"
        v-tooltip="'View on Banno People'"
        @click.stop
      >
        View User
        <ExternalLinkIcon size="14" class="ms-2 align-middle" />
      </a>

      <a
        @click="navigateToTaskDetail"
        class="btn btn-sm btn-outline-secondary"
        :aria-label="`View ${productTitle} task for user ${task.user.identifier}`"
        @click.stop
      >
        View Task
      </a>
    </td>
  </tr>
</template>

<script>
import { PRODUCTS } from '@atomicfi/constants-shared'
import TaskService from '@/services/TaskService'
import TaskStatus from '@/components/Modules/Task/TaskElement/TaskStatus'
import { failReasons } from '@/lib/task-fail-reasons'
import { BANNO_STATUSES, TASK_TYPES, DISTRIBUTION_TYPES } from '@/utils/constants'
import { BoxIcon, ExternalLinkIcon } from 'vue-feather-icons'
import { mapGetters, mapState } from 'vuex'
import { getProductTitle } from '@/utils/task'

export default {
  name: 'TaskRow',
  props: {
    task: {
      type: Object,
      required: true,
    },
    currentProduct: {
      type: String,
      required: true,
    },
  },
  components: { TaskStatus, BoxIcon, ExternalLinkIcon },
  computed: {
    ...mapGetters('customer', ['bannoStatus']),
    ...mapState('customer', ['activeCustomer']),
    bannoShortId() {
      return this.activeCustomer.features?.banno?.shortId
    },
    distributionType() {
      return this.task?.settings?.deposit?.distributionType || '-'
    },
    showBannoPeopleLink() {
      return this.bannoStatus === BANNO_STATUSES.ACTIVE && this.bannoShortId
    },
    taskFailReasonDescription() {
      const { failReason } = this.task
      return failReason ? `${failReason}: ${failReasons[failReason]?.description}` : undefined
    },
    isTestTask() {
      return this.task.type === TASK_TYPES.TEST
    },
    productTitle() {
      return getProductTitle(this.task)
    },
    isDepositProduct() {
      const result = this.currentProduct?.toLowerCase() === PRODUCTS.DEPOSIT.toLowerCase()
      return result
    },
  },
  methods: {
    navigateToTaskDetail() {
      this.$router.push({ name: 'tasks-detail', params: { id: this.task._id } })
    },
    hasDistributionType(type) {
      return (
        this.task.settings?.deposit?.distributionType === DISTRIBUTION_TYPES[type.toUpperCase()]
      )
    },
  },
}
</script>

<style lang="scss" scoped>
.clickable-row {
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #f9f9f9;
  }
}
</style>
