<template>
  <div class="dropdown d-inline">
    <a href="#" class="small text-muted" data-toggle="dropdown" aria-expanded="false">
      Date Range <ChevronDownIcon size="14" class="mb-1" :disabled="isDisabled" />
    </a>
    <div class="dropdown-menu p-3" style="min-width: 300px">
      <div class="quick-filters mb-3">
        <div class="d-flex flex-wrap gap-2">
          <button
            v-for="preset in presets"
            :key="preset.label"
            type="button"
            class="btn btn-sm"
            :class="isActivePreset(preset) ? 'btn-primary' : 'btn-light'"
            @click.prevent="applyPreset(preset)"
          >
            {{ preset.label }}
          </button>
        </div>
      </div>

      <div class="dropdown-divider"></div>

      <v-date-picker
        v-model="dateRange"
        :max-date="new Date()"
        :attributes="pickerAttributes"
        is-range
        @input="onDateRangeChange"
      >
        <template v-slot="{ inputEvents, inputValue }">
          <div class="d-flex align-items-center">
            <input
              class="form-control form-control-sm"
              :value="inputValue.start"
              v-on="inputEvents.start"
              placeholder="Start date"
            />
            <span class="mx-2">to</span>
            <input
              class="form-control form-control-sm"
              :value="inputValue.end"
              v-on="inputEvents.end"
              placeholder="End date"
            />
          </div>
        </template>
      </v-date-picker>

      <div class="mt-2 d-flex justify-content-between">
        <button
          type="button"
          class="btn btn-sm btn-outline-secondary"
          @click.prevent="resetDateRange"
          v-if="hasDateRange"
        >
          Reset
        </button>
        <button
          type="button"
          class="btn btn-sm btn-primary ms-auto"
          @click.prevent="applyDateRange"
          :disabled="!isValidRange"
        >
          Apply
        </button>
      </div>
    </div>
  </div>
</template>

<script>
import { ChevronDownIcon } from 'vue-feather-icons'
import TaskBase from '@/components/Modules/Task/TaskBase'
import moment from 'moment'

export default {
  name: 'TaskFilterDateRange',
  components: { ChevronDownIcon },
  extends: TaskBase,
  data: () => ({
    dateRange: null,
    pickerAttributes: [
      {
        key: 'today',
        dot: true,
        dates: new Date(),
      },
    ],
    presets: [
      {
        label: 'Today',
        getValue: () => ({
          start: moment().startOf('day'),
          end: moment().endOf('day'),
        }),
      },
      {
        label: 'Yesterday',
        getValue: () => ({
          start: moment().subtract(1, 'day').startOf('day'),
          end: moment().subtract(1, 'day').endOf('day'),
        }),
      },
      {
        label: 'Last 7 Days',
        getValue: () => ({
          start: moment().subtract(6, 'days').startOf('day'),
          end: moment().endOf('day'),
        }),
      },
      {
        label: 'Last 30 Days',
        getValue: () => ({
          start: moment().subtract(29, 'days').startOf('day'),
          end: moment().endOf('day'),
        }),
      },
      {
        label: 'This Month',
        getValue: () => ({
          start: moment().startOf('month'),
          end: moment().endOf('month'),
        }),
      },
      {
        label: 'Last Month',
        getValue: () => ({
          start: moment().subtract(1, 'month').startOf('month'),
          end: moment().subtract(1, 'month').endOf('month'),
        }),
      },
    ],
    activePreset: null,
  }),
  created() {
    const last30DaysPreset = this.presets.find((preset) => preset.label === 'Last 30 Days')
    if (last30DaysPreset) {
      this.applyPreset(last30DaysPreset)
    }
  },
  computed: {
    hasDateRange() {
      return this.dateRange?.start && this.dateRange?.end
    },
    isValidRange() {
      if (!this.hasDateRange) return false
      return moment(this.dateRange.end).isSameOrAfter(this.dateRange.start)
    },
    isDisabled() {
      return this.$store.state.task.loadingTasks
    },
  },
  methods: {
    isActivePreset(preset) {
      if (!this.dateRange || !this.dateRange.start || !this.dateRange.end) {
        return false
      }

      const presetRange = preset.getValue()
      const currentStart = moment(this.dateRange.start).startOf('day')
      const currentEnd = moment(this.dateRange.end).endOf('day')

      return (
        currentStart.isSame(presetRange.start, 'day') && currentEnd.isSame(presetRange.end, 'day')
      )
    },
    applyPreset(preset) {
      this.activePreset = preset.label
      const range = preset.getValue()

      this.dateRange = {
        start: range.start.toDate(),
        end: range.end.toDate(),
      }

      this.updateDateRangeFilter({
        startDate: range.start.toISOString(),
        endDate: range.end.toISOString(),
      })
    },
    onDateRangeChange(range) {
      this.dateRange = range
      this.activePreset = null
    },
    applyDateRange() {
      if (!this.isValidRange) return

      const startDate = moment(this.dateRange.start).startOf('day').toISOString()
      const endDate = moment(this.dateRange.end).endOf('day').toISOString()

      this.updateDateRangeFilter({ startDate, endDate })
    },
    resetDateRange() {
      const last30DaysPreset = this.presets.find((preset) => preset.label === 'Last 30 Days')
      if (last30DaysPreset) {
        this.applyPreset(last30DaysPreset)
      }
    },
  },
}
</script>

<style scoped>
.gap-2 {
  gap: 0.5rem;
}
.quick-filters button {
  min-width: calc(50% - 0.25rem);
  margin-bottom: 0.5rem;
}
</style>
